<style lang="scss">
  .mdm-setup {

  }
</style>

<template>
  <!-- Error page-->
  <div>
    <div v-if="loading" class="d-flex text-center flex-column justify-center align-center mt-5">
      <h4 class="mb-4">Verifying details, please wait...</h4>

      <v-progress-circular :indeterminate="true" :rotate="0" :value="0" color="#7B2DA4"></v-progress-circular>
    </div>

    <h4 class="text-center mt-3 mx-4" v-else>You do not have valid access to this app</h4>
  </div>
<!-- / Error page-->
</template>

<script>
// import MagazineService from '@/services/magazineService';

export default {
  components: {},
  data() {
    return {
      loading: true,
    }
  },
  computed: {},
  mounted() {
    // listen for a custom electron event
    window.addEventListener('electronSetup', data => {
      console.log('called electron setup!');
      const Token = data.detail.token;
      if (Token) {
        this.$store.commit('SET_IS_ELECTRON', true);
        this.$store.dispatch('radio/sendLog', {
          description: 'Opened Radio App',
          meta: null,
        });
        this.$router.push({name: 'Home'});
      }
    });
  },
}
</script>
